<template>
  <div>
    <p class="text-2xl mb-6">
      {{
        editing
          ? `Editer le 'Event Type' : ${eventType.name}`
          : "Créer un nouvel 'Event Type'"
      }}
    </p>
    <v-card class="pt-7 pb-3">
      <v-row class="ma-3">
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="8">
                <v-autocomplete
                  outlined
                  v-model="eventType.project"
                  :items="projectsList"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Projet"
                  dense
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="8">
                <v-autocomplete
                  outlined
                  v-model="eventType.parent"
                  :items="eventTypesList"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="'Event Type' parent"
                  dense
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    v-model="eventType.name"
                    dense
                    label="Nom"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    v-model="eventType.label"
                    dense
                    label="Label"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    v-model="eventType.description"
                    outlined
                    dense
                    label="Description"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="saveEventType"
                >
                  Enregistrer le 'Event Type'</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  :to="{ name: 'rmra-event-types-list' }"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { required, integerValidator } from "@core/utils/validation";
import axios from "@axios";
import router from "@/router";
export default {
  name: "EventTypesCreateOrUpdate",
  async created() {
    if ("id" in this.$route.params) {
      this.getRMRAEventTypeById(this.$route.params.id);
      this.editing = true;
    } else {
      this.editing = false;
    }
    this.projectsList = await this.fetchData("/rmra-projects/");
    this.eventTypesList = await this.fetchData("/rmra-event-types/");
  },
  data() {
    return {
      required,
      integerValidator,
      editing: false,
      eventTypesList: [],
      eventType: {
        pk: -1,
        name: "",
        description: "",
        label: "",
        project: null,
      },
      projectsList: [],
    };
  },
  methods: {
    async fetchData(url) {
      const { data } = await axios.get(url);
      return data.items;
    },
    async saveEventType() {
      const isFormValid = this.$refs.form.validate();
      if (!isFormValid) return;
      const payload = {
        name: this.eventType.name,
        label: this.eventType.label,
        rmra_project_id: this.eventType.project,
        description: this.eventType.description,
        parent_id: this.eventType.parent,
      };

      if (this.editing) {
        const { status } = await axios.put(
          `/rmra-event-types/${this.eventType.pk}/`,
          payload
        );
        if (status === 200) {
          await router.push({ name: "rmra-event-types-list" });
        }
      } else {
        const { status } = await axios.post(`/rmra-event-types/`, payload);
        if (status === 200) {
          await router.push({ name: "rmra-event-types-list" });
        }
      }
    },
    async getRMRAEventTypeById(id) {
      const { status, data } = await axios.get(`/rmra-event-types/${id}/`);
      console.log(data);
      if (status === 200) {
        this.eventType = {
          pk: data.id,
          name: data.name,
          label: data.label,
          description: data.description,
          project: data.rmra_project_id,
          parent: data.parent_id,
        };
      }
    },
  },
};
</script>

<style scoped></style>
